<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
@import "assets/css/reset.css";
@import "assets/css/main.css";
@import "assets/css/sub.css";
@import "assets/css/table.css";
@import "assets/css/style.css";
</style>
