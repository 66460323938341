import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const requireAuth = () => (to, from, next) => {
//   const token = localStorage.getItem('access_token')
//   if (token) {
//     return next()
//   }
//   return next('/login')
// }

const alreadySigned = () => (to, from, next) => {
  const token = localStorage.getItem('access_token')
  if (token) {
    return next('/')
  }
  return next()
}

const routes = [
  {
    path: '/login',
    name: 'login',
    beforeEnter: alreadySigned(),
    component: () => import('@/views/Login'),
  },
  {
    path: '/',
    // beforeEnter: requireAuth(),
    component: () => import('@/views/Main'),
    children: [
      {
        path: '',
        beforeEnter: (to, from, next) => {
          next('/main')
        }
      },
      {
        path: '/main',
        name: 'Dashboard',
        component: () => {
          if (!store.getters.isAdmin && !store.getters.isTeamLeader && !store.getters.isManager) {
            return import('@/views/main/ApplyDashboard')
          } else {
            // return import('@/views/main/ApplyDashboard')
            return import('@/views/main/Dashboard')
          }
        }
      },
      {
        path: '/notice',
        name: 'Notice',
        component: () => import('@/views/notice/NoticeList'),
      },
      {
        path: '/contract/apply',
        name: 'ContractTechReq',
        component: () => import('@/views/tech/req/ContractTechReqList'),
      },
      {
        path: '/contract/approval',
        name: 'ContractTechApproval',
        component: () => import('@/views/tech/req/ContractTechApprovalList'),
      },
      {
        path: '/contract/manage',
        name: 'ContractTechManage',
        component: () => import('@/views/tech/req/ContractTechManageList'),
      },
      {
        path: '/contract/search',
        name: 'ContractTechSearch',
        component: () => import('@/views/tech/search/ContractTechSearch'),
      },
      {
        path: '/contract/stat',
        name: 'ContractTechStat',
        component: () => import('@/views/tech/stat/ContractTechStat'),
      },
      {
        path: '/contract/payment/manage',
        name: 'ContractTechPaymentManage',
        component: () => import('@/views/payment/PaymentManageList'),
      },
      {
        path: '/contract/payment/view',
        name: 'ContractTechPaymentView',
        component: () => import('@/views/payment/PaymentViewList'),
      },
      {
        path: '/sys/menu',
        name: 'MenuManage',
        component: () => import('@/views/sys/MenuManage'),
      },
      {
        path: '/sys/auth',
        name: 'AuthManage',
        component: () => import('@/views/sys/AuthManage'),
      },
      {
        path: '/sys/user',
        name: 'UserManage',
        component: () => import('@/views/sys/UserManage'),
      },
      {
        path: '/sys/code',
        name: 'CodeManage',
        component: () => import('@/views/sys/CodeManage'),
      },
      {
        path: '/sys/lab',
        name: 'LabManage',
        component: () => import('@/views/sys/LabManage'),
      },
      {
        path: '/sys/company',
        name: 'CompanyManage',
        component: () => import('@/views/sys/CompanyManage'),
      },
      {
        path: '/sys/inventor',
        name: 'InventorManage',
        component: () => import('@/views/sys/InventorManage'),
      },
      {
        path: '/sys/events',
        name: 'Events',
        component: () => import('@/views/sys/Events'),
      },
      {
        path: '/sys/chatbot',
        name: 'ChatbotManage',
        component: () => import('@/views/sys/ChatbotManage'),
      },
      {
        path: '/sys/alarm',
        name: 'AlarmManage',
        component: () => import('@/views/sys/AlarmManage'),
      },
    ]
  }
]

const router = new VueRouter({
  base: '/',
  routes
})

export default router
