import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import mixins from 'vuetify-multiple-draggable-dialogs'
import utilsMixin from './mixins/GlobalUtilsMixin'

Vue.config.productionTip = false

Vue.mixin(mixins)
Vue.mixin(utilsMixin)

Vue.prototype.$http = axios
const qs = require('qs')
axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, {arrayFormat: 'repeat'})
}
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token')
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
})
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const response = error.response
    if (response.status === 403) {
      // alert('로그인 후 이용해 주시기 바랍니다.')
      localStorage.removeItem('access_token')
      if (process.env.NODE_ENV === 'production') {
        window.location = '/'
      } else {
        window.location.href = 'http://localhost:8080/error/accessDenied'
      }
    } else {
      throw new Error(response.data)
    }
  })

store.dispatch('signInSso')
.then(() => {
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
.catch((err) => {
  localStorage.removeItem('access_token')
  if (process.env.NODE_ENV === 'production') {
    window.location = 'https://iacf.cbnu.ac.kr/sso/login_sso.jsp'
  } else {
    alert(err)
    // window.location.href = 'http://localhost:8080/error/accessDenied'
  }
})
